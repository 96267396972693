export const isNil = (x) => x == null || x == undefined;
import { getContractData } from "./api";

export function formatSignatureLocations(signatureLocations) {
  let copy = [...signatureLocations];
  signatureLocations.forEach((sl, i) => {
    if (
      !isNil(sl.parentId) &&
      !isNil(copy[sl.parentId]) &&
      isNil(copy[sl.parentId].child)
    ) {
      copy[sl.parentId].child = sl;
      copy = copy.filter((x) => x.id !== sl.id);
    }
  });
  return copy;
}

export function sortByProperties(objectArray, properties) {
  return objectArray.sort((a, b) => {
    for (let property of properties) {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      }
    }
    return 0;
  });
}

export function contractDataPromise({contractId, locationsResult, isMobile}) {
  if (locationsResult) {
    return getContractData(contractId).then(r3 => {
      if (r3.success) {
        return {
          contractId: contractId,
          locationsResult: locationsResult,
          companyName: r3.data.rtoCompanyName,
          defaultEmail: r3.data.email,
          contractPaymentData: {
            address: r3.data.address,
            city: r3.data.city,
            phone: r3.data.phone,
            state: r3.data.state,
            zip: r3.data.zip,
            hideAccept: r3.data.hideAccept,
            hideDecline: r3.data.hideDecline
          },
          isCashSale: r3.data.isCashSale,
          isMobile: isMobile
        }            
      } else {
        throw new Error('There was an error resolving the owning RTO company name');
      }
    });
  } else {
    throw new Error('There was an error resolving the signature locations for contract id ' + contractId);
  }
};

export function formatPhoneField(v) {
  if (!v) return v;
  v = v
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/-/g, "")
    .replace(/ /g, "");
  if (v.length === 10) {
    v = v.substring(0, 3) + "-" + v.substring(3, 6) + "-" + v.substring(6, 10);
  }
  return v;
}

export function trim(string, chars = " \t\n\r\x0B") {
  let start = 0;
  let end = string.length;

  while (start < end && chars.includes(string[start])) {
    start++;
  }

  while (end > start && chars.includes(string[end - 1])) {
    end--;
  }

  return string.slice(start, end);
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
