import React from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useAppStore } from "../hooks";

export default function ConfirmModal({ finalizeContract, handleConfirm, isMobile }) {
  const isOpen = useAppStore((x) => x.confirmModalOpen);
  const setIsOpen = useAppStore((x) => x.setConfirmModalOpen);
  const contractId = useAppStore((x) => x.contractId);

  function handleSubmit() {
    handleConfirm(contractId, isMobile);
    finalizeContract();
  }

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalHeader>Complete Contract</ModalHeader>
        <ModalBody className="text-center">Are you sure you are ready to submit?</ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
