import _ from "lodash";

const ROLE_IDS = {
  UserManager: 10,
  UserImpersonator: 11,
  ContractManager: 20,
  ContractViewer: 21,
  ContractCreator: 22,
  InventoryCreator: 30,
  InventoryViewer: 31,
  InventoryManager: 32,
  Dispatcher: 40,
  TransportViewer: 41,
  Driver: 42,
  ReportViewer: 50,
  Administrator: 90,
  SystemAdministrator: 100,
};

const BundleInfoContext = {
  inventory: "inventory",
  contract: "contract",
};

const BUILD_TABS = {
  BUILD_INFO: "BuildInfo",
  BUILD_STEP_STATUS: "BuildStepStatus",
  MANAGE_BUILD_STEPS: "ManageBuildSteps",
};

const ATTACHMENT_TYPE_IDS = {
  LandownerPermission: 1,
  InformationSheet: 2,
  Contract: 3,
  DetailedInformationPage: 4,
  DeliveryConfirmation: 5,
  CustomerContractSignature: 6,
  DeliveryConfirmationSignature: 7,
  DLWConfirmationSignature: 8,
  LandownerPermissionSignature: 9,
  CashSaleInvoice: 10,
  InventoryImage: 11,
  WholesaleInvoice: 12,
  RetailInvoice: 13,
  DriversLicense: 14,
  ContractImage: 15,
  RecurringPaymentAuthorization: 16,
  DLWRejectionSignature: 17,
  InventorySalesReceipt: 24,
  Quote: 25,
  InventoryFloorPlan: 32,
  BuildingPlan: 33,
  TaxExemptionForm: 41
};

const COMPANY_CONTENT_TYPES = {
  CONTRACT: 5,
};

const DEFAULT_TIMEZONE = {label: 'Eastern Standard Time', value: 'Eastern Standard Time'};

const POS_SIGNATURE_TYPES = {
  fullName: 1,
  initial: 2,
  fullNameBinary: 3,
  initialBinary: 4,
};

const MILESTONE_TYPE_IDS = {
  Contract: 1,
  Payment: 2,
  LandlordPermission: 3,
  DeliveryConfirmation: 4,
};

const MILESTONE_STATUS_IDS = {
  Complete: 1,
  Incomplete: 2,
  NotApplicable: 3,
};

const INVENTORY_STATUS_ID = {
  Draft: 5,
  PendingContract: 10,
  InReview: 15,
  InProduction: 17,
  InStock: 20,
  Hold: 30,
  Cancelled: 40,
  Sold: 50,
};

const floorPlanContext = {
  subProductTemplate: "subProductTemplate",
  skuTemplate: "skuTemplate",
  contract: "contract",
  inventory: "inventory",
};

const ERROR_MESSAGES = {
  ERR_INVALID_EMAIL:
    "The requested operation cannot be processed due to an invalid email address",
  ERR_INVALID_PASSWORD: "The proposed password value is not valid",
  ERR_INVALID_USERNAME: "Username not found",
  ERR_INVALID_ROLES:
    "The provided user has an invalid associated list of roles",
  ERR_INVALID_NAME: "Both first and last name are required",
  ERR_INCORRECT_PASSWORD: "Incorrect password for provided username",
  ERR_GENERAL_AUTHENTICATION_FAILURE:
    "Either the username or password provided were incorrect",
  ERR_INVALID_USER:
    "The requested operation cannot be processed due to an invalid user definition",
  ERR_INVALID_SESSION: "The session data is invalid",
  ERR_MISSING_NOTIFICATION: "The requested notification could not be located.",
  ERR_MISSING_CONTRACT: "The specified contract could not be found!",
  ERR_MISSING_ITEM: "The specified item could not be found!",
  ERR_MISSING_USER: "The specified user could not be found!",
  ERR_MISSING_MANUFACTURER: "The specified manufacturer could not be found!",
  ERR_MISSING_TOKEN: "The specified token could not be found!",
  ERR_ACCESS_DENIED: "Access is denied!",
  ERR_DUPLICATE_DETECTED:
    "Adding or editing this item would result in duplicate records",
  ERR_ITEM_ON_HOLD: "This Inventory has an on-hold status and cannot be added",
};

const DEFAULT_BUILD_STEPS = {
  SERIAL_NUMBER: "Assign Serial Number",
  BUILDER: "Assign Builder",
  BUILD: "Approve Build",
  INSPECTION: "Inspection",
  DELIVERY: "Customer Delivery Management",
};

const CREDIT_CARD_TYPES = [
  { label: "Visa", value: 1 },
  { label: "MasterCard", value: 2 },
  { label: "Discover", value: 3 },
  { label: "American Express", value: 4 },
];

const CONTRACT_STATUS_ID = {
  Unknown: -1,
  Draft: 0,
  Quote: 2,
  PreVerification: 5,
  InReview: 10,
  OnHold: 15,
  PendingDelivery: 20,
  InProgress: 20,
  Delivered: 30,
  Completed: 30,
  //PendingProcessing: 38,
  Processed: 40,
  Cancelled: 50
};

const CONTRACT_STATUS_NAME = [
  { name: "Draft", id: CONTRACT_STATUS_ID.Draft, color: "bg-draft" },
  {
    name: "Pre-Verification",
    id: CONTRACT_STATUS_ID.PreVerification,
    color: "bg-draft",
  },
  { name: "In Review", id: CONTRACT_STATUS_ID.InReview, color: "bg-review" },
  { name: "On Hold", id: CONTRACT_STATUS_ID.OnHold, color: "bg-review" },
  {
    name: "Completed",
    id: CONTRACT_STATUS_ID.Delivered,
    color: "bg-delivered",
  },
  {
    name: "In Progress",
    id: CONTRACT_STATUS_ID.PendingDelivery,
    color: "bg-progress",
  },
  // {
  //   name: "Pending Processing",
  //   id: CONTRACT_STATUS_ID.PendingProcessing,
  //   color: "bg-review",
  // },
  { name: "Processed", id: CONTRACT_STATUS_ID.Processed, color: "bg-dark" },
  { name: "Cancelled", id: CONTRACT_STATUS_ID.Cancelled, color: "bg-danger" },
];

const INVENTORY_STATUS_NAME = [
  {
    name: "Draft",
    id: INVENTORY_STATUS_ID.Draft,
    color: "bg-draft",
  },
  {
    name: "Pending Contract",
    id: INVENTORY_STATUS_ID.PendingContract,
    color: "bg-complete",
  },
  { name: "In Review", id: INVENTORY_STATUS_ID.InReview, color: "bg-draft" },
  {
    name: "In Production",
    id: INVENTORY_STATUS_ID.InProduction,
    color: "bg-production",
  },
  { name: "In Stock", id: INVENTORY_STATUS_ID.InStock, color: "bg-progress" },
  { name: "Hold", id: INVENTORY_STATUS_ID.Hold, color: "bg-review" },
  { name: "Cancelled", id: INVENTORY_STATUS_ID.Cancelled, color: "bg-danger" },
  { name: "Sold", id: INVENTORY_STATUS_ID.Sold, color: "bg-dark" },
];

const FOLLOW_UP = {
  RTO: 1,
  CALL: 2,
  HOLD: 3,
  COMMISSION: 4,
};

const ICONS = {
  rto: "desktop",
  call: "phone-alt",
  hold: "hand-paper",
  commission: "dollar-sign",
  contract: "CONTRACT", // "file-signature",
  delivery: "DELIVERY", // "truck",
  landlord: "LANDOWNER", // "home",
  payment: "PAYMENT", // "dollar-sign",
  logo: "LOGO", // "dollar-sign",
};

const SIGNATURE_TYPE_IDS = {
  CUSTOMER_CONTRACT: 1,
  LANDOWNER: 2,
  DELIVERY_CONFIRMATION: 3,
};

const PAYMENT_TYPE_IDS = {
  CREDIT_CARD: 1,
  CHECK: 2,
  MONEY_ORDER: 3,
  CASH: 4,
  CREDIT_CARD_FEE: 5,
  WEB_PAYMENT: 6,
  WEB_SECURITY_DEPOSIT: 7,
  ACH_DEBIT: 8,
  COMMITMENT: 10,
  RETAINED: 11
};

const PAYMENT_TYPES = [
  { value: PAYMENT_TYPE_IDS.CREDIT_CARD, label: "Credit Card" },
  { value: PAYMENT_TYPE_IDS.CHECK, label: "Check" },
  { value: PAYMENT_TYPE_IDS.MONEY_ORDER, label: "Money Order" },
  { value: PAYMENT_TYPE_IDS.CASH, label: "Cash" },
  {
    value: PAYMENT_TYPE_IDS.CREDIT_CARD_FEE,
    label: "Credit Card Fee",
    hidden: true,
  },
  { value: PAYMENT_TYPE_IDS.WEB_PAYMENT, label: "Web Payment", hidden: true },
  {
    value: PAYMENT_TYPE_IDS.WEB_SECURITY_DEPOSIT,
    label: "Security Deposit - Web Payment",
    hidden: true,
  },
  { value: PAYMENT_TYPE_IDS.ACH_DEBIT, label: "ACH Debit" },
  { value: PAYMENT_TYPE_IDS.COMMITMENT, label: "Commitment" },
  { value: PAYMENT_TYPE_IDS.RETAINED, label: "Retained" }
];

const SELECT_OPTION_ID = -999;
const SELECT_OPTION_TEXT = "- Select Option -";
const OTHER = "Other";
const OTHER_ID = -10;
const PRODUCT_TYPES = [
  { value: SELECT_OPTION_ID, label: SELECT_OPTION_TEXT },
  { value: OTHER_ID, label: "Other" },
  { value: 1, label: "Storage Building" },
  { value: 2, label: "Playhouse" },
  { value: 3, label: "Gazebo" },
  { value: 4, label: "Carport" },
  { value: 5, label: "Container" },
  { value: 6, label: "Animal Shelter" }
];

const PRODUCT_TYPE_IDS = {
  OTHER: OTHER_ID,
  STORAGE_BUILDING: 1,
  PLAYHOUSE: 2,
  GAZEBO: 3,
  CARPORT: 4,
  CONTAINER: 5,
  ANIMAL_SHELTER: 6
};

const AccountTypes = [
  { label: "Personal Checking", value: "PersonalChecking", entryClass: "PPD" },
  { label: "Personal Savings", value: "PersonalSavings", entryClass: "PPD" },
  { label: "Business Checking", value: "BusinessChecking", entryClass: "CCD" },
  { label: "Business Savings", value: "BusinessSavings", entryClass: "CCD" },
];

const InvModalType = {
  SerialNum: 0,
  RTOId: 1,
  ProcContract: 2,
  UnpairContract: 3,
  Comment: 4,
  CANCEL: 5,
  EDIT_PRICE: 6,
  DELETE: 7,
  APPLY_DISCOUNT: 8,
};

const ACH_PROCESSORS = [
  { label: "None", value: 0 },
  { label: "Profituity", value: 1 },
];

const ALERT_OBJECT = {
  message: "",
  flavor: "",
};

const ALERT_FLAVOR = {
  success: "success",
  error: "danger",
  warning: "warning",
};

const NEW_OR_USED = {
  NEW_OR_USED: 0,
  ONLY_NEW: 1,
  ONLY_USED: 2,
};

const NEW_OR_USED_OPTIONS = [
  { label: "New Or Used", value: NEW_OR_USED.NEW_OR_USED },
  { label: "Only New", value: NEW_OR_USED.ONLY_NEW },
  { label: "Only Used", value: NEW_OR_USED.ONLY_USED },
];

const ADMIN_TAB_INDEXES = {
    Users: "1",
    TAX_RATES: "3",
    Companies: "4",
    Manufacturers: "5",
    Stores: "6",
    REGIONS: "8"
};

const PATH_NAMES = {
  HOME: "/",
  LOGIN: "/Authentication",
  SIGNATURE: "/sign",
  FORGOT_PASSWORD: "/Authentication/ForgotPassword",
  RTOS: "/Contracts/Search",
  CASH_SALES: "/CashSales/Search",
  INVENTORY: "/Inventory/Search",
  INVENTORY_SALES: "/InventorySales/Search",
  THANK_YOU: "/thankyou",
  QUOTES: "/Quote/Search",
  INVENTORY_VIEW: "/Inventory/View",
  NEW_RTO: "/Contracts/New",
  NEW_QUOTE: "/Quote/New",
  NEW_CASH_SALE: "/CashSale/New",
  NEW_RTO_FROM_INVENTORY: "/Contracts/NewFromInventory",
  NEW_CASH_SALE_FROM_INVENTORY: "/CashSale/NewFromInventory",
  NEW_QUOTE_FROM_INVENTORY: "/Quote/NewFromInventory",
  CASH_SALE_VIEW: "/CashSale/View",
  CASH_SALE_EDIT: "/CashSale/Edit",
  NEW_INVENTORY: "/Inventory/Edit",
  USER_PROFILE: "/Home/UserProfile",
  EDIT_INVENTORY: "/Inventory/Edit",
  RESOURCES_DOCINDEX: "/Resources/Docs",
  RESOURCES_REPORTSINDEX: "/Resources/Reports",
  RESOURCES_EDITDOCLIST: "/Resources/EditDocList",
  RESOURCES_EDITREPORTDEF: "/Resources/ReportList",
  CONTRACT_VIEW: "/Contracts/View",
  CONTRACT_EDIT: "/Contracts/Edit",
  ADMIN: "/admin",
  ADMIN_MANUFACTURERS: "/admin/manufacturers",
  ADMIN_SINGLE_MANUFACTURER: "/admin/manufacturer",
  MANUFACTURERS: "/Manufacturers",
  ADMIN_COMPANIES: "/admin/companies",
  ADMIN_STORES: "/admin/Stores",
  ADMIN_TAXRATES: "/admin/TaxRates",
  ADMIN_REGIONS: "/admin/regions",
  ADMIN_USERS: "/admin/Users",
  COMPANY_ADMIN: "/admin/company",
  STORE_ADMIN: "/admin/store",
  BUILDS: "/Builds",
  BUILD_MANAGEMENT: "/BuildManagement",
  BUILD_DETAILS: "/BuildDetails",
  BUILD_CERTIFY: "/BuildCertify",
  BUILD_ADDON_MANAGEMENT: "/BuildAddOnManagement",
  INSPECTION_DETAILS_MANAGEMENT: "/InspectionDetailsManagement",
  INSPECTIONS: "/Inspections",
  BUILDER_MANAGEMENT: "/BuilderManagement",
  SYSTEM_BUILD_STEP_MANAGEMENT: "/SystemBuildStepManagement",
  TEMPLATE_MANAGEMENT: "/TemplateManagement",
  QUOTE_VIEW: "/Quote/View",
  QUOTE_EDIT: "/Quote/Edit",
  NEW_QUOTE_VERSION: "/Quote/NewVersion",
  CONVERT_QUOTE_TO_RTO: "/ConvertToRTO/Edit",
  CONVERT_QUOTE_TO_CASH_SALE: "/ConvertToCashSale/Edit",
  PRIVACY_POLICY_MOBILE_APP: "/privacypolicy",
};

const DIMENSIONS = {
  W: [
    { value: 6, label: "6" },
    { value: 8, label: "8" },
    { value: 10, label: "10" },
    { value: 12, label: "12" },
    { value: 14, label: "14" },
    { value: 16, label: "16" },
    { value: 0, label: "Other" },
  ],
  L: [
    { value: 8, label: "8" },
    { value: 10, label: "10" },
    { value: 12, label: "12" },
    { value: 14, label: "14" },
    { value: 16, label: "16" },
    { value: 18, label: "18" },
    { value: 20, label: "20" },
    { value: 24, label: "24" },
    { value: 28, label: "28" },
    { value: 32, label: "32" },
    { value: 36, label: "36" },
    { value: 40, label: "40" },
    { value: 42, label: "42" },
    { value: 44, label: "44" },
    { value: 48, label: "48" },
    { value: 50, label: "50" },
    { value: 52, label: "52" },
    { value: 0, label: "Other" },
  ],
  H: [
    { value: 5, label: "5" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 0, label: "Other" },
  ],
};

const ADDITIONAL_WINDOW_OPTION_TYPES = {
  NONE: { label: "None", value: null },
  FLOWERBOX: { label: "Flowerbox", value: 1 },
  SHUTTERS: { label: "Shutters", value: 2 },
};
const ADDITIONAL_WINDOW_OPTION_TYPE_LIST = [
  ADDITIONAL_WINDOW_OPTION_TYPES.NONE,
  ADDITIONAL_WINDOW_OPTION_TYPES.FLOWERBOX,
  ADDITIONAL_WINDOW_OPTION_TYPES.SHUTTERS
];
const ADDITIONAL_DOOR_OPTION_TYPES = {
  NONE: { label: "None", value: null },
  CUSTOM_COLOR: { label: "Custom Color", value: 3 }
};
const ADDITIONAL_DOOR_OPTION_TYPE_LIST = [
  ADDITIONAL_DOOR_OPTION_TYPES.NONE,
  ADDITIONAL_DOOR_OPTION_TYPES.CUSTOM_COLOR
];

const ADDON_TYPES = [
  { value: -1, label: "Text Note" },
  { value: 0, label: "Other" },
  { value: 1, label: "Single Door" },
  { value: 2, label: "Double Door" },
  { value: 3, label: "Window" },
  { value: 4, label: "Single Workbench" },
  { value: 5, label: "Shelving" },
  { value: 6, label: "Outlet" },
  { value: 7, label: "Light" },
  { value: 8, label: "Breaker Box" },
  { value: 9, label: "Switch" },
  { value: 10, label: "Double Workbench" },
  { value: 11, label: "Loft" },
  { value: 12, label: "Double Outlet" }
];

const ADDON_TYPE_ID = {
  TEXT_NOTE: -1,
  OTHER: 0,
  SINGLE_DOOR: 1,
  DOUBLE_DOOR: 2,
  WINDOW: 3,
  SINGLE_WORKBENCH: 4,
  SHELVING: 5,
  OUTLET: 6,
  LIGHT: 7,
  BREAKER_BOX: 8,
  SWITCH: 9,
  DOUBLE_WORKBENCH: 10,
  LOFT: 11,
  DOUBLE_OUTLET: 12
};

const ADDON_TYPE_GROUPS = {
  OTHER: { name: "Other", types: [ADDON_TYPE_ID.OTHER] },
  DOORS: {
    name: "Doors",
    types: [ADDON_TYPE_ID.SINGLE_DOOR, ADDON_TYPE_ID.DOUBLE_DOOR],
  },
  WINDOWS: { name: "Windows", types: [ADDON_TYPE_ID.WINDOW] },
  EXTERIOR_UPGRADES: { name: "Exterior Upgrades", types: [] },
  INTERIOR_UPGRADES: { name: "Interior Upgrades", types: [
    ADDON_TYPE_ID.SINGLE_WORKBENCH, ADDON_TYPE_ID.SHELVING, ADDON_TYPE_ID.DOUBLE_WORKBENCH, ADDON_TYPE_ID.LOFT] },
  ELECTRICAL: { name: "Electrical", types: [ADDON_TYPE_ID.OUTLET, ADDON_TYPE_ID.DOUBLE_OUTLET, ADDON_TYPE_ID.LIGHT, ADDON_TYPE_ID.BREAKER_BOX, ADDON_TYPE_ID.SWITCH] },
  RAMPS: { name: "Ramps", types: [] },
  TEXT_NOTES: { name: "Text Notes", types: [ADDON_TYPE_ID.TEXT_NOTE] },
};

const UNITS_OF_MEASURE = [
  { id: 1, name: "Each" },
  { id: 2, name: "Linear Foot" },
  { id: 3, name: "Sq. Foot"},
  { id: 4, name: "% of Base"}
];

const UNITS_OF_MEASURE_ID = {
  EACH: 1,
  LINEAR_FOOT: 2,
  SQ_FOOT: 3,
  PERCENT_OF_BASE: 4
};

const DOOR_SWING_TYPES = [
  { id: 0, name: "None" },
  { id: 1, name: "Left Hand" },
  { id: 2, name: "Right Hand" },
  { id: 3, name: "Left Hand Active" },
  { id: 4, name: "Right Hand Active" },
  { id: 5, name: "Active" },
];

const SALE_TERM_IDS = {
  IMMEDIATE: 1,
  UPON_PROCESSED: 2,
  IMMEDIATE_WITH_ACCESS: 3,
};

const SALE_TERMS = {
  IMMEDIATE: { value: 1, label: "Immediate" },
  UPON_PROCESSED: { value: 2, label: "Upon Processed" },
  IMMEDIATE_WITH_ACCESS: { value: 3, label: "Immediate with retained access" },
};

const SALE_TERMS_LIST = [
  { label: "Immediate", value: 1 },
  { label: "Upon Processed", value: 2 },
  { label: "Immediate with retained access", value: 3 },
];

const PORCH_TYPES = {
  NONE: { value: 1, label: "None" },
  END: { value: 2, label: "End" },
  SIDE: { value: 3, label: "Side" },
  FOUR_WALL_DELUXE_1: { value: 4, label: "Four Wall Deluxe 1" },
  FOUR_WALL_DELUXE_2: { value: 5, label: "Four Wall Deluxe 2" },
  TWO_WALL_CORNER_1: { value: 6, label: "Two Wall Corner 1" },
};

const PORCH_TYPE_LIST = [
  PORCH_TYPES.NONE,
  PORCH_TYPES.END,
  PORCH_TYPES.SIDE,
  PORCH_TYPES.FOUR_WALL_DELUXE_1,
  PORCH_TYPES.FOUR_WALL_DELUXE_2,
  PORCH_TYPES.TWO_WALL_CORNER_1,
];

const DEFAULT_SKU = () => {
  return {
    id: 0,
    basePrice: 0.0,
    width: 0,
    length: 0,
    name: null,
    manufacturerSubProductId: 0,
    deactivatedAt: null,
  };
};

const CONTRACT_TYPE = {
  RTO: "RTO",
  CASH_SALE: "CashSale",
  QUOTE: "Quote",
};

const SEARCH_AREAS = {
  INVENTORY: "Inventory",
  RTO: "RTO",
  CASH_SALE: "Cash Sale",
  QUOTES: "Quotes",
  BUILDS: "Builds",
  INVENTORY_SALES: "InventorySales"
};

const INIT_PAYMENT_SEVERITIY_FIELD_NAMES = [
  "minimumInitialPayment",
  "minimumInitialPayment2",
  "unitPriceMIN",
];

const UNITPRICE_SEVERITIY_FIELD_NAMES = ["unitPriceMIN"];

const INVENTORY_STATUS_NOT_TO_OVERRIDE = [
  INVENTORY_STATUS_ID.Draft,
  INVENTORY_STATUS_ID.InReview,
  INVENTORY_STATUS_ID.InProduction,
];

const CONTRACT_TERM_TYPES = {
  TWENTY_FOUR_MONTH: { value: 1, label: "24 Month" },
  THIRTY_SIX_MONTH: { value: 2, label: "36 Month" },
  FORTHY_EIGHT_MONTH: { value: 3, label: "48 Month" },
  SIXTY_MONTH: { value: 5, label: "60 Month" },
  SEVENTY_TWO_MONTH: { value: 6, label: "72 Month" },
  OTHER: { value: 4, label: "Other" },
};

const CONTRACT_TERMS = [
  CONTRACT_TERM_TYPES.TWENTY_FOUR_MONTH,
  CONTRACT_TERM_TYPES.THIRTY_SIX_MONTH,
  CONTRACT_TERM_TYPES.FORTHY_EIGHT_MONTH,
  CONTRACT_TERM_TYPES.SIXTY_MONTH,
  CONTRACT_TERM_TYPES.SEVENTY_TWO_MONTH,
  CONTRACT_TERM_TYPES.OTHER,
];
export default {
  UNITPRICE_SEVERITIY_FIELD_NAMES: UNITPRICE_SEVERITIY_FIELD_NAMES,
  INIT_PAYMENT_SEVERITIY_FIELD_NAMES: INIT_PAYMENT_SEVERITIY_FIELD_NAMES,
  SEARCH_AREAS: SEARCH_AREAS,
  FPContext: floorPlanContext,
  BUNDLE_CONTEXT: BundleInfoContext,
  BUILD_TABS,
  BANKING_ACCOUNT_TYPES: AccountTypes,
  ALERT_OBJECT: ALERT_OBJECT,
  BUILD_STEPS: DEFAULT_BUILD_STEPS,
  DIMENSIONS,
  ERROR_MESSAGES,
  ICONS,
  PRODUCT_TYPES,
  PRODUCT_TYPE_IDS,
  PAYMENT_TYPES,
  PAYMENT_TYPE_IDS,
  SIGNATURE_TYPE_IDS,
  INV_MODAL_TYPE: InvModalType,
  FOLLOW_UP,
  NEW_OR_USED,
  NEW_OR_USED_OPTIONS,
  flavor: ALERT_FLAVOR,
  INVENTORY_STATUS_NOT_TO_OVERRIDE: INVENTORY_STATUS_NOT_TO_OVERRIDE,
  INVENTORY_STATUS_ID: INVENTORY_STATUS_ID,
  INVENTORY_STATUS_NAME: INVENTORY_STATUS_NAME,
  CONTRACT_STATUS_ID: CONTRACT_STATUS_ID,
  CREDIT_CARD_TYPES,
  CONTRACT_TERM_IDS: { T24: 1, T36: 2, T48: 3, OTHER: 4, T60: 5, T72: 6 },
  CONTRACT_TERMS: CONTRACT_TERMS,
  CONTRACT_TERM_TYPES: CONTRACT_TERM_TYPES,
  CONTRACT_STATUS_NAME: CONTRACT_STATUS_NAME,
  SORT_DIRECTIONS: {
    ASC: "Ascending",
    DESC: "Descending",
  },
  MOBILE_SIGNATURE_HEIGHT: 150,
  token: "CFU",
  CASH_SALE_INVOICE_TEMPLATE_ID: 1,
  current_user: "cf_current_user",
  NOTIFICATION_TYPE_IDS: {
    NONE: 0,
    EMAIL: 1,
    SMS: 2,
    PHONE: 3,
    FAX: 4,
    EMAIL_AND_SMS: 5
  },
  notificationTypes: [
    { value: 0, label: "None" },
    { value: 1, label: "Email" },
    { value: 2, label: "SMS" },
    //{ value: 3, label: "Phone" },
    //{ value: 4, label: "Fax" },
    { value: 5, label: "Email and SMS" }
  ],
  promotionTypes: [
    {
      value: 1,
      label:
        "Minimum Initial Payment = First month's payment + Lump sum portion of security deposit + Threshold overage",
    },
    {
      value: 2,
      label:
        "Minimum Initial Payment = First month's payment + Percentage portion of security deposit + Threshold overage",
    },
    { value: 3, label: "Minimum Initial Payment = First month's payment + Threshold overage" },
    { value: 5, label: "Minimum Initial Payment = First month's payment" },
    { value: 4, label: "Minimum Initial Payment = (none required)" },
  ],
  severityUnitPrice: "unitPrice",
  severityInitPayment: "initialPaymentAmount",
  severityTypes: [
    { value: 0, label: "Informational" },
    { value: 1, label: "Normal" },
    { value: 2, label: "High" },
    { value: 3, label: "Critical" },
  ],
  paymentProcessorTypes: [
    { value: 0, label: "Unknown" },
    { value: 1, label: "None" },
    { value: 2, label: "Authorize.net" },
    { value: 3, label: "Clearent" },
  ],
  PAYMENT_PROCESSOR_UNKNOWN_ID: 0,
  PAYMENT_PROCESSOR_NONE_ID: 1,
  PATH_NAMES,
  ADMIN_TAB_INDEXES,
  ROLE_IDS,
  ATTACHMENT_TYPE_IDS,
  MILESTONE_TYPE_IDS,
  MILESTONE_STATUS_IDS,
  MOBILE_VISIBILITY_CONTRACT_SIGNATURE_ID: 2,
  OTHER: "Other",
  OTHER_ID,
  SELECT_OPTION_ID,
  SELECT_OPTION_TEXT,
  OTHER_OPTION: { value: OTHER_ID, label: OTHER },
  EMPLOYMENT_TYPES_REQUIRING_EMPLOYER: ["Employed", "Self-Employed"],
  STORAGE_KEYS: {
    LAST_REGION_ID: "lastRegionId",
    LAST_MFG_ID: "lastManufacturerId",
    LAST_SALESPERSON_LABEL: "lastSalespersonLabel",
  },
  EMPTY_STORE: {
    name: "",
    abbr: "",
    sac: false,
    newUser: false,
    isTabletSignatureCapture: false,
    apiKey: "",
    transactionKey: "",
    notifyUponContractApproval: false,
    enableCreditCardPayments: false,
    enableCashSales: false,
    enableRTOContracts: false,
    companyId: null,
    company: null,
    isTrackingCommission: false,
  },
  EMPTY_MFG: {
    name: "",
    defaultManufacturerUnitTypeId: null,
    hideManufacturerUnitTypeSelection: false,
    enableInventory: false,
    isOwner: false,
    isStore: true,
    enableCreditCardPayments: false,
    enableCashSales: false,
    enableRTOContracts: false,
    inventoryWholesaleDiscountPercent: 0,
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    fax: "",
    SAC: false,
    enableShippingInvoice: false,
    company: null,
    stores: [],
  },
  INVENTORY_IMAGE_ADDITIONAL_IMG_URL: "inventoryimageadditional.png",
  INVENTORY_IMAGE_ADDITIONAL_IDS: [26, 27, 28, 29, 30, 31],
  INVENTORY_IMAGE_TYPE_IDS: [
    11, 18, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 31,
  ],
  INVENTORY_IMAGE_TYPES: [
    {
      value: 18,
      label: "Inventory Image Front",
      placeholderImageName: "inventoryimagefront.png",
    },
    {
      value: 19,
      label: "Inventory Image Right",
      placeholderImageName: "inventoryimageright.png",
    },
    {
      value: 20,
      label: "Inventory Image Left",
      placeholderImageName: "inventoryimageleft.png",
    },
    {
      value: 21,
      label: "Inventory Image Back",
      placeholderImageName: "inventoryimageback.png",
    },
    {
      value: 22,
      label: "Inventory Image Interior 1",
      placeholderImageName: "inventoryimageinterior1.png",
    },
    {
      value: 23,
      label: "Inventory Image Interior 2",
      placeholderImageName: "inventoryimageinterior2.png",
    },
    {
      value: 26,
      label: "Inventory Image Add'l",
      placeholderImageName: "inventoryimageadditional.png",
    },
    {
      value: 27,
      label: "Inventory Image Add'l 2",
      placeholderImageName: "inventoryimageadditional2.png",
    },
    {
      value: 28,
      label: "Inventory Image Add'l 3",
      placeholderImageName: "inventoryimageadditional3.png",
    },
    {
      value: 29,
      label: "Inventory Image Add'l 4",
      placeholderImageName: "inventoryimageadditional4.png",
    },
    {
      value: 30,
      label: "Inventory Image Add'l 5",
      placeholderImageName: "inventoryimageadditional5.png",
    },
    {
      value: 31,
      label: "Inventory Image Add'l 6",
      placeholderImageName: "inventoryimageadditional6.png",
    },
  ],
  MIME_MP4: "video/mp4",
  MIME_PDF: "application/pdf",
  MIME_JPG: "image/jpeg",
  MIME_PNG: "image/png",
  MIME_OCTET: "application/octet-stream",
  MIME_XLS: "application/vnd.ms-excel",
  MIME_XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  MIME_CSV: "text/csv",
  MIME_XML: "text/xml",
  NULL_PAYMENT_DATE: "2000-01-01",
  CULTURE_ENGLISH_ID: 1,
  EMPTY_COMPANY: {
    id: null,
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    fax: "",
    email: "",
    remitTo1: "",
    remitTo2: "",
    remitTo3: "",
    remitTo4: "",
    apiKey: "",
    transactionKey: "",
    paymentProcessor: null,
    enableCashSaleByPassReview: false,
  },
  appName: "RTO360",
  roles: [
    {
      label: "User Manager",
      value: ROLE_IDS.UserManager,
      groupName: "Administration",
      name: "User Manager",
      dsc: "Add, change or remove users",
    },
    {
      label: "User Impersonator",
      value: ROLE_IDS.UserImpersonator,
      groupName: "Administration",
      name: "Impersonate",
      dsc: "The ability to impersonate a user",
    },
    {
      label: "Contract Manager",
      value: ROLE_IDS.ContractManager,
      groupName: "Contracts",
      name: "Manage Contracts",
      dsc: "The ability to view, approve, reject and change contracts",
    },
    {
      label: "Contract Viewer",
      value: ROLE_IDS.ContractViewer,
      groupName: "Contracts",
      name: "View Contracts",
      dsc: "The ability to view contracts",
    },
    {
      label: "Contract Creator",
      value: ROLE_IDS.ContractCreator,
      groupName: "Contracts",
      name: "Create Contracts",
      dsc: "The ability to initiate contracts",
    },
    {
      label: "Inventory Creator",
      value: ROLE_IDS.InventoryCreator,
      groupName: "Inventory",
      name: "Create Inventory",
      dsc: "The ability to define new inventory",
    },
    {
      label: "Inventory Viewer",
      value: ROLE_IDS.InventoryViewer,
      groupName: "Inventory",
      name: "View Inventory",
      dsc: "The ability to view existing inventory",
    },
    {
      label: "Inventory Manager",
      value: ROLE_IDS.InventoryManager,
      groupName: "Inventory",
      name: "Manage Inventory",
      dsc: "The ability to edit existing inventory and publish to the web",
    },
    {
      label: "Dispatcher",
      value: ROLE_IDS.Dispatcher,
      groupName: "Transport",
      name: "Dispatcher",
      dsc: "The ability to assign and reassign drivers for transportation purposes",
    },
    {
      label: "Transport Viewer",
      value: ROLE_IDS.TransportViewer,
      groupName: "Transport",
      name: "View Transport",
      dsc: "The ability to view transportation details, including the assigned driver",
    },
    {
      label: "Driver",
      value: ROLE_IDS.Driver,
      groupName: "Transport",
      name: "Driver",
      dsc: "The ability to view assigned transportation tasks and to use the mobile application to view and mark them complete",
    },
    {
      label: "Report Viewer",
      value: ROLE_IDS.ReportViewer,
      groupName: "Reporting",
      name: "View Reports",
      dsc: "The ability to see specifically granted reports",
    },
    {
      label: "Administrator",
      value: ROLE_IDS.Administrator,
      groupName: "Administration",
      name: "Administrator",
      dsc: "The ability to maintain users, maintain reference data, view reports and to see all relevant transactions",
    },
    {
      label: "System Administrator",
      value: ROLE_IDS.SystemAdministrator,
      groupName: "Administration",
      name: "System Administrator",
      dsc: "The ability to view or change any data in the entire system",
    },
  ],
  defaultBank: {
    name: "",
    routeNumber: "",
    addressLine1: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    achAllowed: "",
  },
  defaultPayment: {
    merchantId: 0,
    name: "",
    routeNumber: "",
    accountNumber: "",
    accountType: "",
    amount: null,
    entryClass: "",
    type: "ACHDebit",
  },
  defaultAchValidation: {
    routeNumber: false,
    accountNumber: false,
    accountType: false,
    name: false,
  },
  TOGGLE_BUTTON_COLORS: {
    activeThumb: {
      base: "rgb(0,0,0)",
    },
    inactiveThumb: {
      base: "rgb(0,0,0)",
    },
    active: {
      base: "#8EC44C",
      hover: "#8EC44C",
    },
    inactive: {
      base: "#808080",
      hover: "rgb(95,96,98)",
    },
  },
  DEFAULT_PRICE_ADJUSTMENT_OPTION: {
    label: "-- Price Adjustments --",
    value: 0,
  },
  ADDON_TYPES,
  ADDON_TYPE_GROUPS,
  ADDON_TYPE_ID,
  UNITS_OF_MEASURE,
  UNITS_OF_MEASURE_ID,
  DOOR_SWING_TYPES,
  ACH_PROCESSORS: ACH_PROCESSORS,
  SALE_TERMS_LIST: SALE_TERMS_LIST,
  SALE_TERM_IDS,
  TEMP_SERIAL_NO_STRING3: "TB-UPDATED",
  DEFAULT_SKU: DEFAULT_SKU,
  QUOTE_STRING: "Quote",
  RTO_STRING: "RTO",
  CASH_SALE_STRING: "CashSale",
  QUOTE_MOBILE_VISIBILITY_TYPE_ID: 8,
  ADDITIONAL_WINDOW_OPTION_TYPE_LIST,
  ADDITIONAL_WINDOW_OPTION_TYPES,
  ADDITIONAL_DOOR_OPTION_TYPE_LIST,
  ADDITIONAL_DOOR_OPTION_TYPES,
  DEFAULT_TIMEZONE,
  PORCH_TYPES,
  PORCH_TYPE_LIST,
  CONTRACT_TYPE,
  DEFAULT_LINEAR_FOOT: 4,
  COMPANY_CONTENT_TYPES,
  POS_SIGNATURE_TYPES,
  popOutStyle: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    backgroundColor: "#efefef",
    zIndex: 900
  },
  DEFAULT_SQ_FT: 40
};