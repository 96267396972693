import "bootstrap/dist/css/bootstrap.min.css";
import 'core-js/features/array/at';
import React, { useEffect, useState } from "react";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Alert from "react-s-alert-v3";
import {
  Home,
  IntroVideo,
  PaperlessBilling,
  RecurringPayments,
  SignatureCapture,
} from "./components";
import { getSignatureLocations, resolveContractIdFromToken } from "./api";
import { sortByProperties, contractDataPromise } from "./utils";
import { API_URL, TOP_LEVEL_VIEWS } from "./constants";
import { useAppStore } from "./hooks";
import usePosHub from "./hooks/usePosHub";
import useWindowSize from "./hooks/useWindowSize";

const SignatureCaptureEntry = ({ handleSaveSignature, handleConfirm, routeToken, handleSaveRecurringPaySignature, isMobile }) => {
  const currentView = useAppStore((state) => state.currentView);
  const setCurrentView = useAppStore((x) => x.setCurrentView);
  const setFilePath = useAppStore((x) => x.setFilePath);
  const setContractId = useAppStore((x) => x.setContractId);
  const setCompanyName = useAppStore((x) => x.setCompanyName);
  const setSignatureLocations = useAppStore((x) => x.setSignatureLocations);
  const setPaperlessEmail = useAppStore((x) => x.setPaperlessEmail);
  const contractId = useAppStore((x) => x.contractId);
  const isContractCashSale = useAppStore((x) => x.isContractCashSale);
  const setIsContractCashSale = useAppStore((x) => x.setIsContractCashSale);
  const setContractPaymentData = useAppStore((x) => x.setContractPaymentData);
  const [isSigningContract, setIsSigningContract] = useState(false);
  const [isItMobile, setIsItMobile] = useState(false);
  const {
    completeSignature,
    finalizeContract,
    sendPaymentInfo,
    confirmPaperlessBilling,
    enableRecurring,
    completeRecurringPaySignature,
  } = usePosHub(contractId);

  const { width } = useWindowSize();

  useEffect(() => {
    if (!routeToken) return;
    resolveContractIdFromToken(routeToken, isMobile)
    .then(r => {
      const data = r.data.item1;
      const isMobile = r.data.item2;
      if (r.success) {
        const passcode = r.data;
        return getSignatureLocations(data).then(r2 => {
          return {
            contractId: r2.data.data.item2,
            locationsResult: r2.data.data.item1,
            isMobile: isMobile,
          };
        });
      } else {
        throw new Error('There was an error resolving the contract from the provided token');
      }
    })
    .then(contractDataPromise)
    .then(({contractId, locationsResult, companyName, defaultEmail, contractPaymentData, isCashSale, isMobile}) => {
      const signingContract = localStorage.getItem('signingContract') === 'true';
      setIsSigningContract(signingContract);
      setIsItMobile(isMobile);
      isCashSale || signingContract ? setCurrentView(TOP_LEVEL_VIEWS.SIGNING) : setCurrentView(TOP_LEVEL_VIEWS.WATCHING_VIDEO);
      setContractId(contractId);
      setFilePath(API_URL + "Signature/PreviewContract/" + contractId);
      setSignatureLocations(
        sortByProperties(locationsResult, ["pageIndex", "yPosition"]).map(
          (x, i) => ({ ...x, isIntersecting: true })));
      setCompanyName(companyName);
      setPaperlessEmail(defaultEmail);
      setContractPaymentData(contractPaymentData);
      setIsContractCashSale(isCashSale);
      localStorage.removeItem('signingContract');
    })
    .catch(error => {
      Alert.error(error);
      console.error(error)
    })
  }, [routeToken])

  if (currentView === TOP_LEVEL_VIEWS.WATCHING_VIDEO && !isContractCashSale)
  {
    return (<IntroVideo />);
  }
  if (currentView === TOP_LEVEL_VIEWS.PAPERLESS_BILLING && !isContractCashSale)
  {
    return (<PaperlessBilling confirmPaperlessBilling={confirmPaperlessBilling} />);
  }
  if (currentView === TOP_LEVEL_VIEWS.RECURRING_PAYMENTS && !isContractCashSale)
  {
    return (
      <RecurringPayments
        completeRecurringPaySignature={completeRecurringPaySignature}
        handleSaveRecurringPaySignature={handleSaveRecurringPaySignature}
        sendPaymentInfo={sendPaymentInfo}
        enableRecurring={enableRecurring}
      />
    );    
  }
  if (currentView === TOP_LEVEL_VIEWS.SIGNING)
  {
    return (
      <SignatureCapture
        completeSignature={completeSignature}
        finalizeContract={finalizeContract}
        handleSaveSignature={handleSaveSignature}
        handleConfirm={handleConfirm}
        signingContract={isSigningContract}
        isMobile={isItMobile}
      />
    );    
  }
  return (<Home />);
};

export default SignatureCaptureEntry;
