import React, { useEffect, useState } from "react";
import _ from "lodash";
import {Card, Col, Row, Input, Spinner, Button} from "reactstrap";
import { api } from "../utils";
import classNames from "classnames";
import Alert from "react-s-alert-v3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const defaultSkuAddOn = {
  id: 0,
  addOn: null,
  isAssociated: false,
};

const AddOnCard = (props) => {
    const [addOn, setAddOn] = useState({});
    const [skuAddOn, setSkuAddOn] = useState({});

    useEffect(() => {
      if(!props.a && !props.skuAddOn) return;
      props.a.isLoading = false;
      setAddOn(props.a);
      setSkuAddOn(props.skuAddOn);
      return () => {
          setAddOn({});
          setSkuAddOn({});
      };
    }, [props.a]);

    const cardColor = classNames({
      'primary': skuAddOn ? skuAddOn.isAssociated : false
    });

    const cardClass = classNames('text-dark', 'm-1', 'p-3', {'text-white': skuAddOn ? skuAddOn.isAssociated : false});

    const handleAddOnSelect = () => {
      let payload = {
          id: skuAddOn.subProductSkuAddOnId,
          addOnId: addOn.id,
          manufacturerSubProductSkuId: skuAddOn.subProductSkuId
      }
      handleLoading();
      props.onChangeCallback(payload);
    };

    const handleLoading = () => {
      let newAddon = Object.assign({}, addOn);
      newAddon.isLoading = true;
      setAddOn(newAddon);
    };

    return (
        <>
        {addOn ?
            (<Card
            style={{ cursor: "pointer" }}
            color={cardColor}
            className={cardClass}
            onClick={(e) => {
                handleAddOnSelect();
                e.stopPropagation();
            }}
        >
            <Row>
                <Col sm={1}>
                    {addOn.isLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <Input
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={skuAddOn ? skuAddOn.isAssociated : false}
                      />
                    )}
                </Col>
                <Col className="d-flex justify-content-start">
                    <strong style={{ pointerEvents: "none" }}>
                        {addOn.name}
                    </strong>
                </Col>
            </Row>
        </Card>) : null}</>
    );
};

export default function EditSkuAddOnOpts({ subProductId, sku, mfgId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [addOns, setAddOns] = useState([]);
  const [addonAssociation, setAddOnAssociation] = useState([]);
  const [addOnOptions, setAddOnOptions] = useState([]);
  const [defaultOnlyAddOns, setDefaultOnlyAddOns] = useState([]);

  useEffect(() => {
    getMfgAddOns();
    return () => {
      setAddOns([]);
      setAddOnAssociation([]);
    };
  }, []);

  function getMfgAddOns(){
    api.fetch(`manufacturer/GetSKUAddOns/${mfgId}/${sku.id}`).then(r => {
      if(!r.data.success) return;
      const addOns = r.data.message.mfgAddOns;
      const noDefaultAddOns = _.filter(addOns, addOn => !addOn.isDefault);
      const defaultOnlyAddOns = _.filter(addOns, addOn => addOn.isDefault);
      setAddOnAssociation(r.data.message.skuAddOnAssociations);
      setAddOns(noDefaultAddOns);
      setDefaultOnlyAddOns(defaultOnlyAddOns);
    }).catch(err => console.error(err));
  }

  function refreshAddOnAssociation() {
    api.fetch(`manufacturer/GetSubProductAssociations/${mfgId}/${sku.id}`).then(r => {
      setAddOnAssociation(r.data);
    }).catch(err => console.error(err));
  }

  function copyAddOns() {
    api.fetch(`manufacturer/CopySkuAddOns/${sku.id}`).then(r => {
      if(!r.data.success) return;
      Alert.success("Successfully copied these Add-Ons to the larger SKUs");
    }).catch(err => console.error(err));
  }

  function handleChange(p) {
    p.manufacturerId = mfgId;
    api.post('manufacturer/SaveSkuAddOn', p).then(r => {
      if(!r.data.success) return;
      getMfgAddOns();
    }).catch(err => console.error(err));
  }

  return (<>
    <Row>
      <h3>SKU Add-On Options</h3>
    </Row>
    <Row className={'mb-1'}>
      <small>In order to activate an Add-On for this SKU you need to select it here. When it's blue it's active and when it's white it is not. </small>
    </Row>
    {addOns.length && addonAssociation && addonAssociation.length 
      ? (<Button className='border-success bg-success ms-1' onClick={copyAddOns}>
          Copy Add-Ons to Larger <FontAwesomeIcon icon="copy" />
        </Button>)
      : null
    }
    <div className="d-flex flex-wrap mt-3">
      {sku && addOns && addOns.length && addonAssociation
        ? _.map(addOns, (a) => 
            (<AddOnCard
              key={`aoc-${a.id}`}
              a={a}
              onChangeCallback={handleChange}
              skuAddOn={_.find(addonAssociation, ac => a.id === ac.addOnId)}
            />)
          ) 
        : null}
    </div>
    <Row className={'mt-4'}>
      <h4>Default Only Add-On Options</h4>
    </Row>
    <Row className={'mt-1'}>
      <small>The Add-Ons below will be displayed as standard items in the default layout. The dealer can remove an item if necessary, but cannot increase their quantity.</small>
    </Row>
    <div className="d-flex flex-wrap">
      {sku && defaultOnlyAddOns && defaultOnlyAddOns.length && addonAssociation
        ? _.map(defaultOnlyAddOns, (a) =>
            (<AddOnCard
              key={`aoc-${a.id}`}
              a={a}
              onChangeCallback={handleChange}
              skuAddOn={_.find(addonAssociation, ac => a.id === ac.addOnId)}
            />)
          )
        : null}
    </div>
  </>);
}